// export const BASE_URL = "http://localhost:4700/api/v1";
// export const BASE_URL =
//   "http://ec2-52-59-198-30.eu-central-1.compute.amazonaws.com/api/v1";

export const BASE_URL = "https://api.musuwologistics.co.za/api/v1";

/////// https://main.d3p7nuu4l9e3ns.amplifyapp.com/

export default {
  // -----AUTH------//
  signInAdmin: BASE_URL + "/user/admin/login",
  logOut: BASE_URL + "/user/logout",
  user: BASE_URL + "/user",

  // -----Stats-------//
  getStats: BASE_URL + "/user/stats",

  // -----Coins-------//
  coins: BASE_URL + "/coin",

  // -----Wallets-------//
  wallets: BASE_URL + "/wallet",

  // -----Raffles-------//
  raffles: BASE_URL + "/raffle",
  raffleShipConfirm: BASE_URL + "/raffle/order/confirm",

  /// feedback
  feedback: BASE_URL + "/feedback",

  /// category
  category: BASE_URL + "/category",

  /// pet-category
  petCategory: BASE_URL + "/pet-category",

  /// pet
  pet: BASE_URL + "/pet",

  /// service
  service: BASE_URL + "/delivery",

  /// bookings
  booking: BASE_URL + "/booking",

  /// Disputes
  disputesAll: BASE_URL + "/dispute/getall",
  disputesUpdate: BASE_URL + "/dispute/update",
  disputesDelete: BASE_URL + "/dispute/delete",

  /// Privacy
  privacyCreate: BASE_URL + "/privacy/create",
  privacy: BASE_URL + "/privacy",

  /// Refund
  refundCreate: BASE_URL + "/refund/create",
  refund: BASE_URL + "/refund",

  //Review
  getReviews: BASE_URL + "/review/", // Pass ID

  /// Terms&Conditions
  termsandconditionCreate: BASE_URL + "/termsandcondition/create",
  termsandcondition: BASE_URL + "/termsandcondition",

  // Transactions
  transactionCreate: BASE_URL + "/transaction/create",
  transactionGetAll: BASE_URL + "/transaction/getall",
  transactionUpdate: BASE_URL + "/transaction/update",
  transactionDelete: BASE_URL + "/transaction/delete",

  // Preferences
  preferenceCreate: BASE_URL + "/preference/create",
  preferenceGetAll: BASE_URL + "/preference/getall",
  preferenceUpdate: BASE_URL + "/preference/update",
  preferenceDelete: BASE_URL + "/preference/delete",

  // Courses
  courseGetAll: BASE_URL + "/course/getall",
  courseUpdate: BASE_URL + "/course/update",
  courseDelete: BASE_URL + "/course/delete",
  courseAcceptorReject: BASE_URL + "/course/approval",
};
