import React, { useState, useRef, useEffect } from "react";
import "./modalAddRefund.css";
import {
  Button,
  Modal,
  Input,
  Select,
  InputNumber,
  Checkbox,
  Image,
} from "antd";
import { addIcon, editIcon } from "../../assets";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import { callApi } from "../../api/apiCaller";
import { Buffer } from "buffer";
import routes from "../../api/routes";
import { RedNotify, upload } from "../../helper/helper";

const TinyEditor = ({ onChange, editorRef, value, initialValue }) => {
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
      <Editor
        apiKey="qrzehnsfrdejulr5jbuj3vp67m8pxh2th7meokh6cagu0uzg"
        onChange={onChange}
        //   value={}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        init={{
          plugins: "image link",
          file_picker_types: "file image media",
          relative_urls: false,
          remove_script_host: false,
        }}
      />
      <button onClick={log}>Log editor content</button>
    </>
  );
};

const ModalAddRefund = ({
  setShowModal,
  showModal,
  item,
  setIsLoading,
  addProduct,
  setAddProduct,
}) => {
  const [refund, setRefund] = useState(addProduct ? "" : item?.data);
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const isFormIncomplete = () => {
    return !refund;
  };

  const createPrivacy = async () => {
    if (!refund) {
      alert("Please put refund");
      return;
    }

    let getRes = (res) => {
      console.log("res of create refund", res);
      setShowModal(false);
      setAddProduct(false);
    };

    let body = {
      data: refund.level.content,
    };

    callApi(
      "POST",
      routes.refundCreate,
      body,
      setIsLoading,
      getRes,
      (error) => {
        RedNotify(error.message);
        console.log("error", error);
      }
    );
  };

  const updateRefund = async () => {
    if (!refund) {
      alert("Please put refund");
      return;
    }

    let getRes = (res) => {
      // console.log("res of update product", res);
      setShowModal(false);
    };
    let body = {
      data: refund.level.content,
    };
    console.log("cehck of html", refund.level.content);
    callApi(
      "PATCH",
      `${routes.refund}/${item?._id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  //   console.log("item", title1);
  return (
    <div className="add-product-modal-main-container">
      <div className="add-product-modal-container">
        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Refund</h2>
          {/* <input ref={inputRef} type="text" className='form-control m-2' style={{ width: '80vw' }} value={privacy} onChange={s => setPrivacy(s.target.value)}/> */}
          <TinyEditor
            ref={inputRef}
            editorRef={editorRef}
            initialValue={refund}
            value={refund}
            onChange={(content) => setRefund(content)}
            style={{ width: "80vw" }}
          />
          {/* <TinyEditor ref={inputRef} editorRef={editorRef} initialValue={privacy}  style={{ width: '80vw' }} /> */}
          {/* <Input
            value={privacy}
            placeholder="privacy"
            onChange={(e) => {
              setPrivacy(e.target.value);
            }}
          /> */}
        </div>
        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>
          {addProduct ? (
            <Button
              onClick={createPrivacy}
              style={{ marginLeft: "2rem" }}
              disabled={isFormIncomplete()}
              type="primary"
            >
              Add Refund
            </Button>
          ) : (
            <Button
              onClick={updateRefund}
              style={{ marginLeft: "2rem" }}
              disabled={isFormIncomplete()}
              type="primary"
            >
              Update
            </Button>
          )}
        </div>
      </div>
    </div>

    // <div className="add-product-modal-main-container">

    // </div>
  );
};

export default ModalAddRefund;
