import React, { useState, useRef, useEffect } from "react";
import "./assignRider.css";
import {
  Button,
  Modal,
  Input,
  Select,
  InputNumber,
  Checkbox,
  Image,
} from "antd";
import { addIcon, editIcon } from "../../assets";
import { useSelector } from "react-redux";
import { callApi } from "../../api/apiCaller";
import { Buffer } from "buffer";
import routes from "../../api/routes";
import { RedNotify, upload } from "../../helper/helper";

const ModalAssignRider = ({
  setShowModal,
  showModal,
  item,
  setIsLoading,
  addProduct,
  setAddProduct,
}) => {
  // const [title, setTitle] = useState(addProduct ? "" : item?.title);
  const [riderId, setRiderId] = useState();
  const [riders, setRiders] = useState();

  const isFormIncomplete = () => {
    return !riderId;
  };

  const getRiders = async () => {
    let getRes = (res) => {
      // console.log("res of update product", res);
      setRiders(res.riders);
      // setShowModal(false);
    };

    callApi(
      "GET",
      `${routes.service}/admin/riders/${item?._id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const assignRider = async () => {
    if (!riderId) {
      alert("Please put rider");
      return;
    }

    let getRes = (res) => {
      // console.log("res of update product", res);
      setShowModal(false);
    };

    callApi(
      "PATCH",
      `${routes.service}/admin/accept/${item?._id}/${riderId}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getRiders();
  }, []);
  //   console.log("item", title1);
  return (
    <div className="add-product-modal-main-container">
      <div className="add-product-modal-container">
        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>{item.order.orderno} | Select Rider</h2>
          <select onChange={(e) => setRiderId(e.target.value)}>
            <option value="">Choose Rider</option>
            {riders &&
              riders.map((obj) => {
                return <option value={obj._id}>{obj.name}</option>;
              })}
          </select>
        </div>
        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>

          <Button
            onClick={assignRider}
            style={{ marginLeft: "2rem" }}
            disabled={isFormIncomplete()}
            type="primary"
          >
            Update
          </Button>
        </div>
      </div>
    </div>

    // <div className="add-product-modal-main-container">

    // </div>
  );
};

export default ModalAssignRider;
