import React from "react";
import { Breadcrumb, Layout, Menu, theme } from "antd";
import {
  homeIcon,
  productIcon,
  productOrder,
  redTrash,
  game,
  challenge,
  serviceIcon,
  serviceOrder,
  userIcon,
  studentIcon,
  oragnizerIcon,
  journyIcon,
  goalsIcon,
  categoryIcon,
  speakerIcon,
  amountIcon,
  transactionIcon,
  courseIcon,
  eventPaidIcon,
  usersIcon,
  walletsIcon,
  coinsIcon,
  disputesIcon,
  rafflesIcon,
} from "../assets";
import "./layout.css";
import Header2 from "../components/header/header";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accessToken, refreshToken, userData } from "../redux/userDataSlice";
import { useToken } from "antd/es/theme/internal";
import { callApi } from "../api/apiCaller";
import routes from "../api/routes";
import { useState } from "react";
import Loader from "../components/loader/loader";
import { GreenNotify, RedNotify } from "../helper/helper";
import Dashboard from "../pages/dashboard/stats";
import UserList from "../pages/userList/userList";
import NotFound from "../pages/NotFound/notFound";
import Disputes from "../pages/disputes/disputes";
import Raffles from "../pages/bookings/bookings";
import Termsandconditions from "../pages/termsandconditions/termsandconditions";
import Privacy from "../pages/privacy/privacy";
import ProviderList from "../pages/providerList/providerList";
import FeedbackList from "../pages/feedbackList/feedbackList";
import Category from "../pages/category/category";
import PetCategory from "../pages/PetCategory/PetCategory";
import PetList from "../pages/petList/petList";
import Services from "../pages/services/services";
import ReviewList from "../components/reviewsModal/showReviews";
import Bookings from "../pages/bookings/bookings";
import Chat from "../pages/chat/chat";
import Refund from "../pages/refund/refund";
import { useSelector } from "react-redux";
import PendingDeliveries from "../pages/pendingDeliveries/pendingDeliveries";

const { Header, Content, Footer, Sider } = Layout;
const LayoutDashboard = () => {
  const [isloading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userDataNew = useSelector((e) => e.userDataSlice.userData);
  console.log("REDUXUSERDATA", userDataNew);
  const logOut = () => {
    let getRes = (res) => {
      if (res.status == 200) {
        GreenNotify(res.message);
        dispatch(userData(null));
        dispatch(accessToken(""));
        dispatch(refreshToken(""));
      } else {
        RedNotify(res.message);
      }
    };

    let body = {
      device: {
        id: localStorage.getItem("deviceId"),
        deviceToken: "xyz",
      },
    };

    callApi("POST", routes.logOut, body, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
    >
      <Loader loading={isloading} />
      <Sider style={{ background: "#0B1B2D" }} width={280}>
        <div
          style={{
            padding: "2rem 0",
            textAlign: "center",
            cursor: "pointer",
            color: "white",
            fontSize: "3rem",
          }}
          onClick={() => navigate("/")}
        >
          PICK'IT UP
        </div>
        <Menu
          style={{ marginTop: "5rem" }}
          inlineCollapsed={true}
          theme="dark"
          defaultSelectedKeys={["0"]}
          mode="inline"
          // items={itemsMain}
        >
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/client-list")}
            icon={<img className="side-bar-icon" src={usersIcon} />}
            key="1"
          >
            Clients
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/driver-list")}
            icon={<img className="side-bar-icon" src={disputesIcon} />}
            key="20"
          >
            Drivers
          </Menu.Item>
          {/* <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/category-list")}
            icon={<img className="side-bar-icon" src={walletsIcon} />}
            key="2"
          >
            Categories
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/pet-category")}
            icon={<img className="side-bar-icon" src={coinsIcon} />}
            key="10"
          >
            Pet Categories
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/pets")}
            icon={<img className="side-bar-icon" src={challenge} />}
            key="60"
          >
            Pets
          </Menu.Item> */}
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/deliveries")}
            icon={<img className="side-bar-icon" src={walletsIcon} />}
            key="70"
          >
            Deliveries
          </Menu.Item>
          {userDataNew.role === "admin" && (
            <Menu.Item
              style={{ marginBottom: "2rem" }}
              onClick={() => navigate("/pending-deliveries")}
              icon={<img className="side-bar-icon" src={amountIcon} />}
              key="600"
            >
              Over Empty Deliveries
            </Menu.Item>
          )}
          {/* <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/disputes")}
            icon={<img className="side-bar-icon" src={disputesIcon} />}
            key="11"
          >
            Disputes
          </Menu.Item> */}
          {/* <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/bookings")}
            icon={<img className="side-bar-icon" src={rafflesIcon} />}
            key="5"
          >
            Bookings
          </Menu.Item> */}
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/feedbacks")}
            icon={<img className="side-bar-icon" src={productIcon} />}
            key="50"
          >
            Feedbacks
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/privacy")}
            icon={<img className="side-bar-icon" src={productOrder} />}
            key="15"
          >
            Privacy
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/refund")}
            icon={<img className="side-bar-icon" src={coinsIcon} />}
            key="39"
          >
            Refund Policy
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/termsandconditions")}
            icon={<img className="side-bar-icon" src={serviceIcon} />}
            key="16"
          >
            Terms and Conditions
          </Menu.Item>
          <Menu.Item
            style={{ marginBottom: "2rem" }}
            onClick={() => navigate("/chat")}
            icon={<img className="side-bar-icon" src={challenge} />}
            key="19"
          >
            Customer Support
          </Menu.Item>
          <Menu.Item onClick={logOut} key="89">
            Log Out
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header2 />
        <Content
          style={{
            background: "#fff",
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard />}></Route>
            <Route path="/client-list" element={<UserList />}></Route>
            <Route path="/driver-list" element={<ProviderList />}></Route>
            {/* <Route path="/category-list" element={<Category />}></Route> */}
            <Route path="/deliveries" element={<Services />}></Route>
            <Route
              path="/pending-deliveries"
              element={<PendingDeliveries />}
            ></Route>
            {/* <Route path="/bookings" element={<Bookings />}></Route>
            <Route path="/pet-category" element={<PetCategory />}></Route>
            <Route path="/pets" element={<PetList />}></Route>
            <Route path="/disputes" element={<Disputes />}></Route> */}
            <Route path="/feedbacks" element={<FeedbackList />}></Route>
            <Route path="/privacy" element={<Privacy />}></Route>
            <Route path="/refund" element={<Refund />}></Route>
            <Route path="/reviews" element={<ReviewList />}></Route>
            <Route path="/chat" element={<Chat />}></Route>
            <Route
              path="/termsandconditions"
              element={<Termsandconditions />}
            ></Route>
            <Route path="/*" element={<NotFound />}></Route>
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutDashboard;
