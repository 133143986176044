import React, { useState, useRef } from "react";
import "./modalUpdateUser.css";
import { Button, Modal, Input, InputNumber, Checkbox, Image } from "antd";
import { addIcon, editIcon } from "../../assets";
import { useSelector } from "react-redux";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import { upload } from "../../helper/helper";
import DatePicker from "react-datepicker";
const UpdateUser = ({
  setShowModal,
  showModal,
  item,
  setIsLoading,
  addProduct,
  setAddProduct,
}) => {
  // const [title, setTitle] = useState(addProduct ? "" : item?.title);
  const [name, setName] = useState(item?.name);
  const [number, setNumber] = useState(item?.number);
  const [verified, setVerified] = useState(item?.verified);
  const [image, setImage] = useState(item?.image);

  const isFormIncomplete = () => {
    return !name || !number;
  };

  const fileInputRef = useRef(null);

  const pickImageFile = () => {
    fileInputRef.current.click();
  };

  const dummyImage =
    "https://icon-library.com/images/default-profile-icon/default-profile-icon-6.jpg";
  //console.log("add product", addProduct);

  const handleFileChange = (event) => {
    const fileList = event.target.files;

    // Perform operations with the selected file(s)
    console.log(fileList);
  };

  const updateStudent = () => {
    let getRes = (res) => {
      console.log("res of update Student", res);
      setShowModal(false);
    };

    let body = {
      name,
      number,
      image,
      verified,
    };

    callApi(
      "PATCH",
      `${routes.user}/${item?._id}`,
      body,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };
  //   console.log("item", title1);

  return (
    <div className="add-product-modal-main-container">
      <div className="add-product-modal-container">
        <div style={{ marginTop: "2rem" }}></div>

        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Name</h2>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            // min={0}
            // placeholder="0"
          />
        </div>
        <div
          style={{ marginBottom: "2rem" }}
          className="add-product-modal-input-title"
        >
          <h2>Number</h2>
          <Input
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            // min={0}
            // placeholder="0"
          />
        </div>
        <div className="product-list-image" style={{ marginBottom: "2rem" }}>
          <h2>Verification Status</h2>
          <select
            defaultValue={verified}
            onChange={(e) => setVerified(e.target.value)}
          >
            <option value="true">Verified</option>
            <option value="false">Un-Verified</option>
          </select>
        </div>
        <div className="product-list-image" style={{ marginBottom: "2rem" }}>
          <Image width={50} src={image} alt="profile-image" />
          <Button
            style={{ marginTop: "1rem", marginLeft: "2rem" }}
            onClick={() => {
              setImage(dummyImage);
            }}
            type="default"
            danger
          >
            Reset
          </Button>
        </div>

        <div className="modal-btn-container"></div>
        <div style={{ marginBottom: "3rem" }}>
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            type="default"
            danger
          >
            Cancel
          </Button>
          <Button
            onClick={updateStudent}
            disabled={isFormIncomplete()}
            style={{ marginLeft: "2rem" }}
            type="primary"
          >
            Update
          </Button>
        </div>
      </div>
    </div>

    // <div className="add-product-modal-main-container">

    // </div>
  );
};

export default UpdateUser;
