import React, { useState, useEffect } from "react";
import "./pendingDeliveries.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import {
  crossIcon,
  homeIcon,
  editIcon,
  redTrash,
  trueIcon,
} from "../../assets";
import UpdateLearner from "../../components/updateUser/modalUpdateUser";
import DescriptionModal from "../../components/descriptionModal/descriptionModal";

import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";
import { GreenNotify, RedNotify } from "../../helper/helper";
import UpdateStudent from "../../components/updateUser/modalUpdateUser";
import UpdateUser from "../../components/updateUser/modalUpdateUser";
import LocationModal from "../../components/descriptionModal/locationModal";
import UpdatePet from "../../components/updatePet/modalUpdatePet";
import Details from "../../components/details/details";
import StarRating from "../../components/ratingStars";
import { useNavigate } from "react-router-dom";
import { setParam } from "../../components/params";
import { useSelector } from "react-redux";
import ModalAssignRider from "../../components/assignRider/assignRider";
var userData = null;
const PendingDeliveries = () => {
  userData = useSelector((e) => e.userDataSlice.userData);
  console.log("REDUXUSERDATA", userData);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // const [isloading, setIsLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalDes, setShowModalDes] = useState(false);
  const [details, setDetails] = useState({});
  const [showModalContent, setShowModalContent] = useState(false);
  const [showModalLoc, setshowModalLoc] = useState(false);
  const [pDescription, setPdescription] = useState("");
  const [addStudent, setAddStudent] = useState();
  const [getStudent, setGetStudent] = useState(false);

  function percentage(percent, total) {
    return ((percent / 100) * total).toFixed(2);
  }

  const getAllUsers = () => {
    let getRes = (res) => {
      // console.log('usersssssss',res?.data?.data[0]);
      setStudents(res?.data?.data);
    };

    callApi(
      "GET",
      routes.service + "?status=over-empty",
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const rejectDel = (dId) => {
    let getRes = (res) => {
      // console.log('usersssssss',res?.data?.data[0]);
      setRefresh(!refresh);
      if (res.status === 200) {
        GreenNotify(res.message);
      } else {
        RedNotify(res.message);
      }
    };

    callApi(
      "PATCH",
      routes.service + `/admin/cancel/${dId}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const delUser = (uId) => {
    let getRes = (res) => {
      // console.log('usersssssss',res?.data?.data[0]);
      setRefresh(!refresh);
      GreenNotify("Delivery Deleted Successfully");
    };

    callApi(
      "DELETE",
      routes.service + `/${uId}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllUsers();
  }, [refresh]);

  let columns;

  columns = [
    {
      title: "Order No",
      dataIndex: "orderno",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Cancel/Reject",
      dataIndex: "cancel",
      align: "right",
      className: "action-column-header",
    },
    {
      title: "Assign Rider",
      dataIndex: "edit",
      align: "right",
      className: "action-column-header",
    },
    {
      title: "Special Note",
      dataIndex: "note",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "PickUpLocation",
      dataIndex: "picklocation",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "DropOutLocation",
      dataIndex: "droplocation",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },

    {
      title: "Total Distance",
      dataIndex: "distance",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
      // filters: [
      //   {
      //     text: "Pending",
      //     value: "pending",
      //   },
      //   {
      //     text: "Over Empty",
      //     value: "over-empty",
      //   },
      //   {
      //     text: "Over Accepted",
      //     value: "over-accepted",
      //   },
      //   {
      //     text: "Inprogress",
      //     value: "delivery-inprogress",
      //   },
      //   {
      //     text: "Completed",
      //     value: "delivery-completed",
      //   },
      //   {
      //     text: "Canceled",
      //     value: "delivery-canceled",
      //   },
      // ],
      // onFilter: (value, record) => record?.status?.indexOf(value) === 0,
    },
    {
      title: "PickUpTime",
      dataIndex: "time",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Is Over 15Kg",
      dataIndex: "isOver15Kg",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Is Need Packing",
      dataIndex: "isNeedPacking",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Is Need Packaging",
      dataIndex: "isNeedPackaging",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Packing Size",
      dataIndex: "packingSize",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Is Reviewed",
      dataIndex: "isReviewed",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Cancelled By",
      dataIndex: "cancelledBy",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Cancelled Reason",
      dataIndex: "cancelReason",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Owner",
      dataIndex: "owner",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Driver",
      dataIndex: "rider",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Driver ID",
      dataIndex: "riderId",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "center",
      className: "action-column-header",
    },

    {
      title: "Delete",
      dataIndex: "delete",
      align: "right",
      className: "action-column-header",
    },
  ];

  const data = students
    .filter((obj) => !obj.order.isRefund)
    ?.map((item, index) => {
      // console.log('reward',index,':',item?.dob);
      return {
        key: index,
        driverCommission: percentage(30, item?.price),
        packingPrice:
          item?.packingSize && item?.packingSize === "small"
            ? 40
            : item?.packingSize && item?.packingSize === "medium"
            ? 50
            : item?.packingSize && item?.packingSize === "large"
            ? 60
            : item?.packingSize && item?.packingSize === "x-large"
            ? 70
            : "not-available",
        orderno: item?.order?.orderno ? item?.order?.orderno : "not-available",
        cancelReason: item?.cancelReason ? (
          <div>
            <p style={{ fontSize: "12px" }}>
              {item?.cancelReason?.length > 10
                ? item?.cancelReason.substring(0, 10) + "..."
                : item?.cancelReason}{" "}
              {item?.cancelReason?.length > 10 && (
                <span
                  onClick={() => {
                    setShowModalDes(true);
                    setPdescription(item?.cancelReason);
                  }}
                  style={{
                    color: "#34adf4",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  See More{" "}
                </span>
              )}
            </p>
          </div>
        ) : (
          "not-available"
        ),
        note: item?.specialNote ? (
          <div>
            <p style={{ fontSize: "12px" }}>
              {item?.specialNote?.length > 10
                ? item?.specialNote.substring(0, 10) + "..."
                : item?.specialNote}{" "}
              {item?.specialNote?.length > 10 && (
                <span
                  onClick={() => {
                    setShowModalDes(true);
                    setPdescription(item?.specialNote);
                  }}
                  style={{
                    color: "#34adf4",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  See More{" "}
                </span>
              )}
            </p>
          </div>
        ) : (
          "not-available"
        ),
        picklocation: item?.pickUpLocation?.address ? (
          <div>
            <p style={{ fontSize: "12px" }}>
              {(item?.pickUpLocation?.address + ``).length > 10
                ? (item?.pickUpLocation?.address + ``).substring(0, 10) + "..."
                : item?.pickUpLocation?.address + ``}{" "}
              {(item?.pickUpLocation?.address + ``).length > 10 && (
                <span
                  onClick={() => {
                    // setModalTitle("Location");
                    setshowModalLoc(true);
                    setPdescription(item?.pickUpLocation?.address + ``);
                  }}
                  style={{
                    color: "#34adf4",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  See More{" "}
                </span>
              )}
            </p>
          </div>
        ) : (
          "not-available"
        ),
        droplocation: item?.dropOutLocation?.address ? (
          <div>
            <p style={{ fontSize: "12px" }}>
              {(item?.dropOutLocation?.address + ``).length > 10
                ? (item?.dropOutLocation?.address + ``).substring(0, 10) + "..."
                : item?.dropOutLocation?.address + ``}{" "}
              {(item?.dropOutLocation?.address + ``).length > 10 && (
                <span
                  onClick={() => {
                    // setModalTitle("Location");
                    setshowModalLoc(true);
                    setPdescription(item?.dropOutLocation?.address + ``);
                  }}
                  style={{
                    color: "#34adf4",
                    cursor: "pointer",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  See More{" "}
                </span>
              )}
            </p>
          </div>
        ) : (
          "not-available"
        ),
        price: item?.price ? item?.price : "not-available",
        fee: item?.order?.fee ? item?.order?.fee : "not-available",
        amount: item?.order?.amount ? item?.order?.amount : "not-available",
        distance: item?.totalDistanceInKm
          ? item?.totalDistanceInKm
          : "not-available",
        status: "Over Empty",
        packingSize: item?.packingSize ? item?.packingSize : "not-available",
        category: item?.petCategory?.title
          ? item?.petCategory?.title
          : "not-available",
        coverImage: (
          <div className="product-list-image">
            <Image width={50} src={item?.coverImage} alt="cover-image" />
          </div>
        ),
        images: (
          <span
            onClick={() => {
              setShowModalContent(!showModalContent);
              setDetails(item);
            }}
            style={{ color: "#34ADF4", cursor: "pointer" }}
          >
            See
          </span>
        ),
        // ratingsAverage:
        //   item?.ratingsAverage && item.ratingsQuantity > 0 ? (
        //     <StarRating rating={item.ratingsAverage} />
        //   ) : (
        //     "Not Rated Yet"
        //   ),
        // reviews: (
        //   <div className="server-roles-trash-btn">
        //     <Button
        //       className="server-roles-trash-btn"
        //       style={{
        //         width: "120px",
        //         color: "white",
        //         backgroundColor: "#0B1B2D",
        //         // borderColor: "#52c41a",
        //       }}
        //       // style={{ width: '80px' }}
        //       // onClick={() => {
        //       //   <ReviewList data={item}/>
        //       // }}
        //       // disabled={item?.isComplete ? false : true}
        //       onClick={() => {
        //         // <Route path="/reviews" element={<ReviewList data={item}/>}></Route>
        //         navigate(
        //           `/reviews?${setParam({
        //             item: JSON.stringify({ _id: item._id, name: item.title }),
        //           })}`
        //         );
        //       }}
        //     >
        //       View Reviews
        //     </Button>
        //   </div>
        // ),
        isOver15Kg: (
          <div className="product-list-image">
            <Image
              width={50}
              src={item?.isOver15Kg ? trueIcon : crossIcon}
              alt="image"
            />
          </div>
        ),
        isNeedPacking: (
          <div className="product-list-image">
            <Image
              width={50}
              src={item?.isNeedPacking ? trueIcon : crossIcon}
              alt="image"
            />
          </div>
        ),
        isNeedPackaging: (
          <div className="product-list-image">
            <Image
              width={50}
              src={item?.isNeedPackaging ? trueIcon : crossIcon}
              alt="image"
            />
          </div>
        ),
        isReviewed: (
          <div className="product-list-image">
            <Image
              width={50}
              src={item?.isReviewed ? trueIcon : crossIcon}
              alt="image"
            />
          </div>
        ),
        cancelledBy: item?.cancelledBy ? item?.cancelledBy : "not-available",
        owner: item?.creator?.name ? item?.creator?.name : "not-available",
        rider: item?.rider?.name ? item?.rider?.name : "not-available",
        riderId: item?.rider?.userId ? item?.rider?.userId : "not-available",
        createdAt: moment(item?.createdAt)
          .local()
          .format("DD, MMM, YYYY , hh:mm A"),
        time: moment(item?.deliveryStartTime)
          .local()
          .format("DD, MMM, YYYY , hh:mm A"),
        edit: (
          <Button
            style={{ color: "white", backgroundColor: "blue" }}
            onClick={() => {
              setStudent(item);
              // dispatch(productItem(item));
              setShowModal(true);
              setAddStudent(false);
            }}
          >
            Assign
          </Button>
        ),
        delete: (
          <div
            onClick={() => {
              delUser(item?._id);
            }}
            className="product-list-edit-icon"
          >
            <img src={redTrash} />
          </div>
        ),
        cancel: (
          <Button
            style={{ color: "white", backgroundColor: "red" }}
            onClick={() => {
              rejectDel(item?._id);
            }}
          >
            Reject
          </Button>
        ),
      };
    });

  useEffect(() => {
    getAllUsers();
  }, [showModal, getStudent]);

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      {showModal && (
        <ModalAssignRider
          showModal={showModal}
          setShowModal={setShowModal}
          item={student}
          setIsLoading={setIsLoading}
          addProduct={addStudent}
          setAddProduct={setAddStudent}
        />
      )}
      {showModalDes && (
        <DescriptionModal
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
      {showModalLoc && (
        <LocationModal
          showModalDes={showModalLoc}
          setShowModalDes={setshowModalLoc}
          description={pDescription}
        />
      )}
      {showModalContent && (
        <Details
          showModal={showModalContent}
          setShowModal={setShowModalContent}
          client={details}
        />
      )}
      <div className="admin-products-main-container">
        <Loader loading={isloading} />
        <Breadcrumb separator=">" className="bread-crumb">
          <div className="configure-server-home-icon">
            <img src={homeIcon} alt="home-icon" />
          </div>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Delivery List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="configure-server-roles-main-heading-container">
          <h1>Delivery List</h1> <div></div>
        </div>
        <div className="server-roles-tb-main-container">
          <Table
            rowClassName={getRowClassName}
            columns={columns}
            dataSource={data}
            pagination={true}
            className="subscriptionapi-table"
          ></Table>
        </div>
      </div>
    </div>
  );
};

export default PendingDeliveries;
