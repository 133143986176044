import React from "react";
import "./description.css";

const LocationModal = ({ showModalDes, setShowModalDes, description, modalTitle }) => {
  if (!modalTitle) {
    modalTitle = "Description"
  }
  return (
    <div
      onClick={() => setShowModalDes(false)}
      className="add-product-modal-main-container"
    >
      <div
        style={{ marginTop: "29rem", width: "70rem" }}
        className="add-product-modal-container product-description-detail"
      >
        <h1>Location</h1>
        <h2>{description}</h2>
      </div>
    </div>
  );
};

export default LocationModal;
