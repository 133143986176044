import React from "react";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import "./details.css";
import { redTrash } from "../../assets";
import moment from "moment";

const Details = ({ showModal, setShowModal, client }) => {
  return (
    <div
      // onClick={() => setShowModal(!showModal)}
      className="add-product-modal-main-container"
    >
      <div
        style={{ marginTop: "29rem", width: "90rem" }}
        className="add-product-modal-container"
      >
        <div className="description-modal-description-main-container">
          <h1>Documents</h1>

          <h2>Driving License</h2>
          <div className="product-list-image">
            <Image
              style={{ marginBottom: "4%", objectFit: "contain" }}
              width={200}
              height={200}
              sty
              src={client.drivingLicenseFront}
              alt="image-unavailable"
            />
          </div>
          <div className="product-list-image">
            <Image
              style={{ marginBottom: "4%", objectFit: "contain" }}
              width={200}
              height={200}
              sty
              src={client.drivingLicenseBack}
              alt="image-unavailable"
            />
          </div>
          <h2>Passport</h2>
          <div className="product-list-image">
            <Image
              style={{ marginBottom: "4%", objectFit: "contain" }}
              width={200}
              height={200}
              sty
              src={client.passport}
              alt="image-unavailable"
            />
          </div>
          <h2>Residence Proof</h2>
          <a
            href={client.residenceProof}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              style={{
                marginBottom: "2%",
                marginTop: "2%",
                width: "100px",
                height: "40px",
                backgroundColor: "#0B1B2D",
                color: "white",
              }}
            >
              View
            </Button>
          </a>
          <Button
            style={{
              marginBottom: "2%",
              marginTop: "2%",
              width: "100px",
              height: "40px",
              backgroundColor: "#0B1B2D",
              color: "white",
            }}
            onClick={() => setShowModal(!showModal)}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Details;
