import React, { useEffect, useState } from "react";
import "./stats.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import Loader from "../../components/loader/loader";
import {
  //   homeIcon,
  //   orderIcon1,
  //   productIcon,
  //   productIcon1,
  //   redTrash,
  //   serviceIcon,
  //   serviceIcon1,
  gameIcon,
  postIcon,
  amountIcon,
  userIcon,
  rewardRefundIcon,
  studentIcon,
  oragnizerIcon,
  categoryIcon,
  courseIcon,
  eventPaidIcon,
  speakerIcon,
  transactionIcon,
  eventFreeIcon,
} from "../../assets";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import moment from "moment/moment";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import { useMemo } from "react";

const Dashboard = () => {
  const [value, setValue] = useState(new Date());
  const [isloading, setIsLoading] = useState(true);
  const [clients, setClients] = useState(0);
  const [drivers, setDrivers] = useState(0);
  const [reviews, setReviews] = useState(0);
  const [feedbacks, setFeedbacks] = useState(0);
  const [deliveriesPending, setDeliveriesPending] = useState(0);
  const [deliveriesEmpty, setDeliveriesEmpty] = useState(0);
  const [deliveriesInprogress, setDeliveriesInprogress] = useState(0);
  const [deliveriesCompleted, setDeliveriesCompleted] = useState(0);
  const [deliveriesCanceled, setDeliveriesCanceled] = useState(0);
  const getAllUserData = () => {
    let getRes = (res) => {
      console.log("res of user list", res);
      setClients(res?.clients);
      setDrivers(res?.drivers);
      setReviews(res?.reviews);
      setFeedbacks(res?.feedbacks);
      setDeliveriesPending(res?.deliveriesPending);
      setDeliveriesEmpty(res?.deliveriesEmpty);
      setDeliveriesInprogress(res?.deliveriesInprogress);
      setDeliveriesCompleted(res?.deliveriesCompleted);
      setDeliveriesCanceled(res?.deliveriesCanceled);
    };
    callApi("GET", routes.getStats, null, setIsLoading, getRes, (error) => {
      console.log("error", error);
    });
  };

  useMemo(() => {
    getAllUserData();
    // getAllProductsorServices();
    // getAllProductAndServicesOrders();
  }, [
    clients,
    drivers,
    reviews,
    feedbacks,
    deliveriesPending,
    deliveriesEmpty,
    deliveriesInprogress,
    deliveriesCompleted,
    deliveriesCanceled,
  ]);

  const stateArr = [
    {
      title: "Clients",
      count: `${clients}`,
      icon: studentIcon,
    },
    {
      title: "Drivers",
      count: `${drivers}`,
      icon: oragnizerIcon,
    },
    {
      title: "Reviews",
      count: `${reviews}`,
      icon: categoryIcon,
    },
    {
      title: "Feedbacks",
      count: `${feedbacks}`,
      icon: courseIcon,
    },
    {
      title: "Deliveries Pending",
      count: `${deliveriesPending}`,
      icon: eventPaidIcon,
    },
    {
      title: "Deliveries Empty",
      count: `${deliveriesEmpty}`,
      icon: transactionIcon,
    },
    {
      title: "Deliveries Inprogress",
      count: `${deliveriesInprogress}`,
      icon: speakerIcon,
    },
    {
      title: "Deliveries Completed",
      count: `${deliveriesCompleted}`,
      icon: eventFreeIcon,
    },
    {
      title: "Deliveries Canceled",
      count: `${deliveriesCanceled}`,
      icon: transactionIcon,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          {/* <img src={homeIcon} alt="home-icon" /> */}
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-main-container">
        <div className="dashboard-state-container">
          {stateArr.map((item) => (
            <div className="dashboard-state-count-container">
              <div className="dashboard-state-icon">
                <img src={item.icon} alt="icon" />
              </div>
              <div className="dashboard-state">
                <h2>{item.title}</h2>
                <p>{item.count}</p>
              </div>
            </div>
          ))}

          {/* <div className="dashboard-state-count-container"></div> */}
        </div>
        <div className="dashboard-pie-chart-container">
          <Clock size={120} value={value} />
          <p>
            Current time:{" "}
            <span style={{ color: "#0B1B2D", fontWeight: "700" }}>
              {moment(new Date()).format("DD, MMM, YYYY , hh:mm:ss A")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
