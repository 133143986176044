import React, { useState, useEffect } from "react";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import { crossIcon, homeIcon, redTrash, trueIcon } from "../../assets";
import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";
import { getAllParams } from "../../components/params";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import StarRating from "../ratingStars";

const ReviewList = () => {
  const { item } = getAllParams();
  const parsedData = JSON.parse(item);
  // console.log("Param Data>>>>",parsedData)
  // const [users, setUsers] = useState([]);
  // const [blockedUsers, setBlockedUsers] = useState([]);
  // const [isloading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [reviews, setReviews] = useState([]);
  const getAllReviews = () => {
    let getRes = (res) => {
      setReviews(res?.data?.data);
    };

    callApi(
      "GET",
      `${routes.getReviews}?driver=${parsedData?._id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const columns = [
    {
      title: "Review",
      dataIndex: "review",
      className: "role-name-column-header",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
  ];

  useEffect(() => {
    getAllReviews();
  }, []);

  const data = reviews?.map((item, index) => {
    // console.log('bbbbb',item?.blocked);
    return {
      key: index,
      review: item?.review ? item?.review : "Default",
      rating: item?.rating ? <StarRating rating={item.rating} /> : "Default",

      createdAt: item?.createdAt
        ? moment(item?.createdAt).local().format("YYYY-MM-DD HH:mm:ss")
        : "Default",
    };
  });

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Reviews</Breadcrumb.Item>
        <Breadcrumb.Item>Driver</Breadcrumb.Item>
        <Breadcrumb.Item>{parsedData?.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Reviews List</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table"
        ></Table>
      </div>
    </div>
  );
};

export default ReviewList;
