import React, { useState, useEffect } from "react";
import "./feedbackList.css";
import { Breadcrumb, Button, Select, Image } from "antd";
import {
  addIcon,
  crossIcon,
  editIcon,
  homeIcon,
  redTrash,
  trueIcon,
} from "../../assets";
import { Table } from "antd";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import Loader from "../../components/loader/loader";
import { useDispatch } from "react-redux";
import DescriptionModal from "../../components/descriptionModal/descriptionModal";
import moment from "moment/moment";
import ModalAddGoal from "../../components/updateGoal/modalAddGoal";
import StarRating from "../../components/ratingStars";
import LocationModal from "../../components/descriptionModal/locationModal";
import Details from "../../components/details/details";

const FeedbackList = () => {
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [singleData, setSingleData] = useState();
  const [addcourse, setAddCourse] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalLoc, setshowModalLoc] = useState(false);
  const [showModalDes, setShowModalDes] = useState(false);
  const [pDescription, setPdescription] = useState("");
  const [getService, setGetService] = useState(false);
  const getDataArray = () => {
    let getRes = (res) => {
      // console.log(111111,res?.data?.data[0].title);

      const filteredFeedbacks = res?.data?.data.filter(
        (feedback) => feedback.feedback !== ""
      );

      setDataArray(filteredFeedbacks);
      // console.log('aaaaaaa',res?.data?.data[0].media);
      console.log("res of get Goals", res);
      // setShowModal(false);
    };

    callApi(
      "GET",
      routes.feedback + "/getAll",
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const DeleteSingleData = (item) => {
    setGetService(false);
    let getRes = (res) => {
      console.log("deleting Goals");
      console.log("res of delete Goals", res);
      setGetService(true);
    };

    callApi(
      "DELETE",
      `${routes.feedback}/delete/${item?._id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const columns = [
    {
      title: "Feedback",
      dataIndex: "feedback",
      align: "right",
      className: "action-column-header",
    },
    {
      title: "Feedback Creator",
      dataIndex: "feedbackcreator",
      className: "role-name-column-header",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "right",
      className: "action-column-header",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "right",
      className: "action-column-header",
    },
    // {
    //   title: "Edit",
    //   dataIndex: "edit",
    //   align: "right",
    //   className: "action-column-header",
    // },
  ];

  //   Row Data
  const data = dataArray?.map((item, index) => {
    return {
      key: index,
      feedbackcreator: item?.creator?.name
        ? item?.creator?.name
        : "not-available",
      feedback: (
        <div>
          <p style={{ fontSize: "12px" }}>
            {item?.feedback?.length > 30
              ? item?.feedback?.substring(0, 30) + "..."
              : item?.feedback}{" "}
            {item?.feedback?.length > 30 && (
              <span
                onClick={() => {
                  setShowModalDes(true);
                  setPdescription(item?.feedback);
                }}
                style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
              >
                {" "}
                See More{" "}
              </span>
            )}
          </p>
        </div>
      ),
      createdAt: moment(item?.createdAt)
        .local()
        .format("DD, MMM, YYYY , hh:mm A"),
      delete: (
        <div
          onClick={() => DeleteSingleData(item)}
          className="server-roles-trash-btn"
        >
          <img src={redTrash} alt="" />
        </div>
      ),
      // edit: (
      //   <div
      //     onClick={() => {
      //       setCourse(item);
      //       // dispatch(productItem(item));
      //       setShowModal(true);
      //       setAddCourse(false);
      //     }}
      //     className="product-list-edit-icon"
      //   >
      //     <img src={editIcon} />
      //   </div>
      // ),
    };
  });

  useEffect(() => {
    getDataArray();
  }, [showModal, getService]);

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      {showModalDes && (
        <DescriptionModal
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Feedbacks</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Feedbacks</h1> <div></div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table"
        ></Table>
      </div>
    </div>
  );
};

export default FeedbackList;
