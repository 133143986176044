import React, { useState, useEffect } from "react";
import "./termsandconditions.css";
import { Breadcrumb, Button, Select, Image } from "antd";
import { addIcon, editIcon, homeIcon, redTrash } from "../../assets";
import { Table } from "antd";
import routes from "../../api/routes";
import { callApi } from "../../api/apiCaller";
import Loader from "../../components/loader/loader";
import { useDispatch } from "react-redux";
import DescriptionModal from "../../components/descriptionModal/descriptionModal";
import moment from "moment/moment";
import ModalAddGoal from "../../components/updateGoal/modalAddGoal";
import HtmlModal from "../../components/htmlReturnModal/returnHtml";
import ModalAddPrivacy from "../../components/updatePrivacy/modalAddPrivacy";
import ModalAddTerms from "../../components/updateTerms/modalAddTerms";

const Termsandconditions = () => {
  const dispatch = useDispatch();
  const [isloading, setIsLoading] = useState(false);
  const [termsandconditions, setTermsandconditions] = useState([]);
  const [termandcondition, setTermandcondition] = useState();
  const [addTermandcondition, setAddTermandcondition] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDes, setShowModalDes] = useState(false);
  const [pDescription, setPdescription] = useState("");
  const [getTermandcondition, setGetTermandcondition] = useState(false);
  const getTermsandconditions = () => {
    let getRes = (res) => {
      // console.log(111111,res?.data?.data[0].title);
      setTermsandconditions(res?.data?.data);
      // console.log('aaaaaaa',res?.data?.data[0].media);
      console.log("res of get Termsandconditions", res);
      // setShowModal(false);
    };

    callApi(
      "GET",
      routes.termsandcondition,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const DeleteTermandcondition = (item) => {
    setGetTermandcondition(false);
    let getRes = (res) => {
      console.log("deleting Termandcondition");
      console.log("res of delete Termandcondition", res);
      setGetTermandcondition(true);
    };

    callApi(
      "DELETE",
      `${routes.termsandcondition}/${item?._id}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const columns = [
    {
      title: "Term and Condition",
      dataIndex: "termandcondition",
      className: "role-name-column-header",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "right",
      className: "action-column-header",
    },
    {
      title: "Delete",
      dataIndex: "delete",
      align: "right",
      className: "action-column-header",
    },
    {
      title: "Edit",
      dataIndex: "edit",
      align: "right",
      className: "action-column-header",
    },
  ];

  //   Row Data
  const data = termsandconditions?.map((item, index) => {
    return {
      key: index,
      privacy: item?.data,
      termandcondition: (
        <div>
          <p style={{ fontSize: "12px" }}>
            {item?.data.length > 10
              ? item?.data.substring(0, 10) + "..."
              : item?.data}{" "}
            {item?.data.length > 10 && (
              <span
                onClick={() => {
                  setShowModalDes(true);
                  setPdescription(item?.data);
                }}
                style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
              >
                {" "}
                See More{" "}
              </span>
            )}
          </p>
        </div>
      ),
      createdAt: moment(item?.createdAt)
        .local()
        .format("DD, MMM, YYYY , hh:mm A"),
      delete: (
        <div
          onClick={() => DeleteTermandcondition(item)}
          className="server-roles-trash-btn"
        >
          <img src={redTrash} alt="" />
        </div>
      ),
      edit: (
        <div
          onClick={() => {
            setTermandcondition(item);
            // dispatch(productItem(item));
            setShowModal(true);
            setAddTermandcondition(false);
          }}
          className="product-list-edit-icon"
        >
          <img src={editIcon} />
        </div>
      ),
    };
  });

  useEffect(() => {
    getTermsandconditions();
  }, [showModal, getTermandcondition]);

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      {showModal && (
        <ModalAddTerms
          showModal={showModal}
          setShowModal={setShowModal}
          item={termandcondition}
          setIsLoading={setIsLoading}
          addProduct={addTermandcondition}
          setAddProduct={setAddTermandcondition}
        />
      )}
      {showModalDes && (
        <HtmlModal
          showModalDes={showModalDes}
          setShowModalDes={setShowModalDes}
          description={pDescription}
        />
      )}
      <Loader loading={isloading} />
      <Breadcrumb separator=">" className="bread-crumb">
        <div className="configure-server-home-icon">
          <img src={homeIcon} alt="home-icon" />
        </div>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Term and Condition</Breadcrumb.Item>
      </Breadcrumb>
      <div className="configure-server-roles-main-heading-container">
        <h1>Term and Condition</h1>
        <div
          onClick={() => {
            setAddTermandcondition(true);
            setShowModal(true);
          }}
          className="server-roles-add-btn"
        >
          <img src={addIcon} alt="" />
          <p>Add New Term and Condition </p>
        </div>
      </div>
      <div className="server-roles-tb-main-container">
        <Table
          rowClassName={getRowClassName}
          columns={columns}
          dataSource={data}
          pagination={true}
          className="subscriptionapi-table"
        ></Table>
      </div>
    </div>
  );
};

export default Termsandconditions;
