import React, { useState, useEffect } from "react";
import "./userList.css";
import { Breadcrumb, Button, Select, Table, Image } from "antd";
import {
  crossIcon,
  homeIcon,
  editIcon,
  redTrash,
  trueIcon,
} from "../../assets";
import UpdateLearner from "../../components/updateUser/modalUpdateUser";
import DescriptionModal from "../../components/descriptionModal/descriptionModal";

import { callApi } from "../../api/apiCaller";
import routes from "../../api/routes";
import Loader from "../../components/loader/loader";
import moment from "moment/moment";
import { GreenNotify } from "../../helper/helper";
import UpdateStudent from "../../components/updateUser/modalUpdateUser";
import UpdateUser from "../../components/updateUser/modalUpdateUser";
import LocationModal from "../../components/descriptionModal/locationModal";

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  // const [isloading, setIsLoading] = useState(false);
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [showModalDes, setShowModalDes] = useState(false);
  const [showModalLoc, setshowModalLoc] = useState(false);
  const [pDescription, setPdescription] = useState("");
  const [addStudent, setAddStudent] = useState();
  const [getStudent, setGetStudent] = useState(false);

  const getAllUsers = () => {
    let getRes = (res) => {
      // console.log('usersssssss',res?.data?.data[0]);
      setStudents(res?.data?.data);
    };

    callApi(
      "GET",
      routes.user + "?role=client",
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  const delUser = (uId) => {
    let getRes = (res) => {
      // console.log('usersssssss',res?.data?.data[0]);
      setRefresh(!refresh);
      GreenNotify("Client Deleted Successfully");
    };

    callApi(
      "DELETE",
      routes.user + `/${uId}`,
      null,
      setIsLoading,
      getRes,
      (error) => {
        console.log("error", error);
      }
    );
  };

  useEffect(() => {
    getAllUsers();
  }, [refresh]);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      className: "role-name-column-header",
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Number",
      dataIndex: "number",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Location",
      dataIndex: "location",
      align: "right",
      className: "action-column-header",
    },
    // {
    //   title: "Gender",
    //   dataIndex: "gender",
    //   align: "center",
    //   className: "type-name-column-header",
    //   width: 400,
    //   // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,

    //   filters: [
    //     {
    //       text: "Male",
    //       value: "male",
    //     },
    //     {
    //       text: "Female",
    //       value: "female",
    //     },
    //     {
    //       text: "Other",
    //       value: "other",
    //     },
    //   ],
    //   onFilter: (value, record) => record?.gender?.indexOf(value) === 0,
    // },
    {
      title: "Verified",
      dataIndex: "verified",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Complete",
      dataIndex: "complete",
      align: "center",
      className: "type-name-column-header",
      width: 400,
      // render: (text) => <span style={{ color: "#34ADF4" }}>{text}</span>,
    },
    {
      title: "Profile Picture",
      dataIndex: "profilePicture",
      align: "center",
      className: "action-column-header",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      align: "center",
      className: "action-column-header",
    },
    // {
    //   title: "Edit",
    //   dataIndex: "edit",
    //   align: "right",
    //   className: "action-column-header",
    // },

    {
      title: "Delete",
      dataIndex: "delete",
      align: "right",
      className: "action-column-header",
    },
  ];

  const data = students?.map((item, index) => {
    // console.log('reward',index,':',item?.dob);
    return {
      key: index,
      name: item?.name ? item?.name : "not-available",
      email: item?.email,
      number: item?.number ? item?.number : "not-available",
      // gender: item?.gender,
      verified: (
        <div className="product-list-image">
          <Image
            width={50}
            src={item?.verified ? trueIcon : crossIcon}
            alt="profile-image"
          />
        </div>
      ),
      complete: (
        <div className="product-list-image">
          <Image
            width={50}
            src={item?.isComplete ? trueIcon : crossIcon}
            alt="profile-image"
          />
        </div>
      ),
      profilePicture: (
        <div className="product-list-image">
          <Image width={50} src={item?.image} alt="profile-image" />
        </div>
      ),
      location: item?.location?.address ? (
        <div>
          <p style={{ fontSize: "12px" }}>
            {(item?.location?.address + ``).length > 10
              ? (item?.location?.address + ``).substring(0, 10) + "..."
              : item?.location?.address + ``}{" "}
            {(item?.location?.address + ``).length > 10 && (
              <span
                onClick={() => {
                  // setModalTitle("Location");
                  setshowModalLoc(true);
                  setPdescription(item?.location?.address + ``);
                }}
                style={{ color: "#34adf4", cursor: "pointer", fontWeight: 600 }}
              >
                {" "}
                See More{" "}
              </span>
            )}
          </p>
        </div>
      ) : (
        "not-available"
      ),
      createdAt: moment(item?.createdAt)
        .local()
        .format("DD, MMM, YYYY , hh:mm A"),
      edit: (
        <div
          onClick={() => {
            setStudent(item);
            // dispatch(productItem(item));
            setShowModal(true);
            setAddStudent(false);
          }}
          className="product-list-edit-icon"
        >
          <img src={editIcon} />
        </div>
      ),
      delete: (
        <div
          onClick={() => {
            delUser(item?._id);
          }}
          className="product-list-edit-icon"
        >
          <img src={redTrash} />
        </div>
      ),
    };
  });

  useEffect(() => {
    getAllUsers();
  }, [showModal, getStudent]);

  const getRowClassName = (record, index) => {
    if (index % 2 === 0) {
      return "server-role-even-row";
    }
    return "server-role-odd-row";
  };
  return (
    <div className="admin-products-main-container">
      {showModal && (
        <UpdateUser
          showModal={showModal}
          setShowModal={setShowModal}
          item={student}
          setIsLoading={setIsLoading}
          addProduct={addStudent}
          setAddProduct={setAddStudent}
        />
      )}
      {showModalLoc && (
        <LocationModal
          showModalDes={showModalLoc}
          setShowModalDes={setshowModalLoc}
          description={pDescription}
        />
      )}
      <div className="admin-products-main-container">
        <Loader loading={isloading} />
        <Breadcrumb separator=">" className="bread-crumb">
          <div className="configure-server-home-icon">
            <img src={homeIcon} alt="home-icon" />
          </div>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Client List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="configure-server-roles-main-heading-container">
          <h1>Client List</h1> <div></div>
        </div>
        <div className="server-roles-tb-main-container">
          <Table
            rowClassName={getRowClassName}
            columns={columns}
            dataSource={data}
            pagination={true}
            className="subscriptionapi-table"
          ></Table>
        </div>
      </div>
    </div>
  );
};

export default UserList;
